import { extendTheme } from "@chakra-ui/react"
import { mode, transparentize } from "@chakra-ui/theme-tools"

const cookieConsentStyles = {
  "div#hs-eu-cookie-confirmation": {
    fontFamily: `Geograph, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important`,
    bg: "transparent",
    borderTop: "none",
    maxWidth: 896,
    left: "50% !important",
    transform: "translateX(-50%)",
    borderRadius: 2,
    border: "0 !important",
  },
  "div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom": {
    boxShadow: "none",
    bottom: [0, 4],
  },
  "div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button": {
    fontWeight: "bold !important",
    backgroundColor: "#0082CB !important",
    border: 0,
    borderRadius: 2,
  },
  "div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button, div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-decline-button": {
    borderRadius: 99,
  },
  "div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area": {
    display: "flex",
  },
  "div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner": {
    margin: 2,
    borderRadius: 2,
  },
}

const theme = extendTheme({
  fonts: {
    heading: `Geograph, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `Geograph, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  colors: {
    text: "#202020",
    brand: "#0082CB",
    dark: "#0B1B2C",
    /*
    button: {
      50: "#0082CB",
      100: "#0078BD",
      200: "#006FAF",
      300: "#0066A0",
      400: "#005C92",
      500: "#005383",
      600: "#004A75",
      700: "#004166",
      800: "#002E49",
      900: "#001C2C",
    },
    */
    blue: {
      50: "#daf8ff",
      100: "#aee4ff",
      200: "#7ed1ff",
      300: "#4dbfff",
      400: "#23acfe",
      500: "#0f93e5",
      600: "#0072b3",
      700: "#005281",
      800: "#003150",
      900: "#001120",
    },
  },

  styles: {
    global: (props) => ({
      html: {
        height: "-webkit-fill-available",
      },
      body: {
        minHeight: "100vh",
        minHeight: "-webkit-fill-available",
      },
      "html, body": { bg: props.colorMode === "dark" ? "dark" : "white" },
      "p:not(:last-child)": {
        mb: "1em",
      },
      h2: {
        fontSize: ["2xl", "3xl"],
        fontWeight: "bold",
        mb: 4,
      },
      h3: {
        fontSize: ["1xl", "2xl"],
        fontWeight: "bold",
        mb: 4,
      },
      ul: {
        pl: 8,
      },
      ...cookieConsentStyles,
    }),
  },

  components: {
    Heading: {
      baseStyle: {
        letterSpacing: "-.015em",
      },
    },
    Container: {
      baseStyle: {
        px: ["1.5rem", "3rem", "4rem"],
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "full",
      },
      defaultProps: {
        variant: "solid",
        size: "md",
        colorScheme: "blue",
      },
    },
  },
})

export default theme
