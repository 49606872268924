import React, { useContext } from "react"
import { Link, Stack } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import { LocalizationContext } from "../context/LocalizationContext"

const NavLink = (props) => (
  <Link
    as={GatsbyLink}
    to={props.to}
    color={props.color}
    fontWeight="bold"
    fontSize={["3xl", null, "md"]}
  >
    {props.children}
  </Link>
)

const Navigation = ({ color }) => {
  const { language, translate } = useContext(LocalizationContext)
  const prefix = language === "en" || language === "zh" ? "/" : `/${language}/`

  return (
    <Stack
      direction={["column", null, "row"]}
      alignItems={["center", null, "flex-start"]}
      spacing={[2, null, 6]}
    >
      <NavLink to={prefix} color={color}>
        {translate("navigation.home")}
      </NavLink>
      {["en", "fi"].includes(language) && (
        <NavLink to={`${prefix}blog`} color={color}>
          {translate("navigation.blog")}
        </NavLink>
      )}
      <NavLink to={translate("navigation.shop.url")} color={color}>
        {translate("navigation.shop.name")}
      </NavLink>
    </Stack>
  )
}

export default Navigation
