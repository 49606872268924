import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import { LocalizationContext } from "../context/LocalizationContext"
import { ChakraProvider } from "@chakra-ui/react"
import theme from "../theme"

const Layout = ({ children, overlayHeader = false, pageContext }) => {
  const { language, setLanguage } = useContext(LocalizationContext)
  const { language: pageLanguage } = pageContext || {}
  if (pageLanguage && pageLanguage !== language) {
    setLanguage(pageLanguage)
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ChakraProvider theme={theme}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        overlay={overlayHeader}
      />
      <main>{children}</main>
      <Footer />
    </ChakraProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
