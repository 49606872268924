/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { LocalizationContext } from "../context/LocalizationContext"

function SEO({ description, lang, meta, title, alternates, frontpage }) {
  const { translate, setLanguage } = useContext(LocalizationContext)
  setLanguage(lang)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
      }
    `
  )

  const subTitle = title !== "" ? `- ${title}` : ``
  const metaTitle = `${translate("meta.title")} ${subTitle}`
  const metaDescription = translate("meta.description")
  const siteUrl = "https://www.xwander.fi"
  const siteUrlZh = "https://www.xwander.cn"

  let hrefLinks = []
  if (alternates) {
    hrefLinks = alternates.map((alternate) => {
      const normalizedLanguage = alternate.language.code.toLowerCase()
      if (normalizedLanguage === "en") {
        return {
          rel: `alternate`,
          hreflang: `en`,
          href: `${siteUrl}/${alternate.slug}`,
        }
      } else if (normalizedLanguage === "zh") {
        return {
          rel: `alternate`,
          hreflang: `zh`,
          href: `${siteUrlZh}/${alternate.slug}`,
        }
      } else {
        return {
          rel: `alternate`,
          hreflang: normalizedLanguage,
          href: `${siteUrl}/${normalizedLanguage}/${alternate.slug}`,
        }
      }
    })
  }

  // Frontpage conditional
  if (frontpage) {
    if (lang === "zh") {
      hrefLinks.push({
        rel: `canonical`,
        href: `${siteUrlZh}`,
      })
    } else {
      const locale =
        lang.toLowerCase() === "en" ? "" : `/${lang.toLowerCase()}/`
      hrefLinks.push({
        rel: `canonical`,
        href: `${siteUrl}${locale}`,
      })
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      link={hrefLinks}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
