import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {
  Box,
  Button,
  Flex,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  VisuallyHidden,
  useDisclosure,
} from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import Logo from "./logo"
import Navigation from "./navigation"
import LanguageSelector from "./languageSelector"

const MobileMenu = ({ color }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "menu.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Button
        onClick={onOpen}
        variant="link"
        display={["block", null, "none"]}
        color={color}
      >
        <HamburgerIcon boxSize={6} />
        <VisuallyHidden>Menu</VisuallyHidden>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex="modal"
        >
          <Image
            fluid={data.bg.childImageSharp.fluid}
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <ModalContent bg="transparent" my={0}>
          <ModalHeader px={4}>
            <Logo />
          </ModalHeader>
          <ModalCloseButton top={5} right={5} color="white" />
          <ModalBody>
            <Grid
              mt={8}
              gridTemplateColumns="repeat(auto-fit, 32px)"
              gridGap={4}
              justifyContent="center"
            >
              <LanguageSelector mobile />
            </Grid>
            <Flex height="100%" justifyContent="center" alignItems="center">
              <Navigation color="white" />
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="center" pb={8}>
            <Stack spacing={4}>
              <Button
                as="a"
                variant="link"
                color="white"
                fontWeight="normal"
                fontSize="sm"
                href="https://www.xwander.fi"
              >
                Adventure Center Nordic
              </Button>
              <Button
                as="a"
                variant="link"
                color="white"
                fontWeight="normal"
                fontSize="sm"
                href="https://www.xwander.es"
              >
                Adventure Center Canary Islands
              </Button>
              <Button
                as="a"
                variant="link"
                color="white"
                fontWeight="normal"
                fontSize="sm"
                href="https://www.xwander.com"
              >
                Xwander Shop
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MobileMenu
