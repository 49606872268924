import React, { useContext } from "react"
import { Flex, Box, Stack, useColorMode, Button } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import Logo from "./logo"
import LanguageSelector from "./languageSelector"
import { LocalizationContext } from "../context/LocalizationContext"
import { SunIcon, MoonIcon } from "@chakra-ui/icons"
import MobileMenu from "./mobileMenu"
import Navigation from "./navigation"

const Header = ({ siteTitle, overlay }) => {
  const { translate, getLocale } = useContext(LocalizationContext)
  const locale = getLocale()
  const logoLink = locale === "en" || locale === "zh" ? "/" : `/${locale}`
  const { colorMode, toggleColorMode } = useColorMode()

  const color = overlay ? "white" : colorMode === "light" ? "text" : "white"

  return (
    <Box
      as="header"
      position={overlay ? "absolute" : "static"}
      zIndex={10}
      width="100%"
    >
      {overlay && (
        <Box
          position="absolute"
          bottom="-100%"
          top={0}
          width="100%"
          zIndex={-1}
          bg="linear-gradient(180deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.49) 9%, rgba(0,0,0,0.47) 17%, rgba(0,0,0,0.44) 25%, rgba(0,0,0,0.40) 32%, rgba(0,0,0,0.35) 38%, rgba(0,0,0,0.30) 44%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.20) 56%, rgba(0,0,0,0.15) 62%, rgba(0,0,0,0.11) 68%, rgba(0,0,0,0.07) 75%, rgba(0,0,0,0.04) 83%, rgba(0,0,0,0.01) 91%, rgba(0,0,0,0.00) 100%)"
          sx={{ backgroundBlendMode: "screen" }}
        ></Box>
      )}
      <Flex
        justifyContent="space-between"
        alignItems={["center", null, "flex-end"]}
        p={{ base: 4, md: 6 }}
        px={{ lg: 8 }}
      >
        <GatsbyLink to={logoLink} title={siteTitle} sx={{ zIndex: 9999 }}>
          <Logo color={color} />
        </GatsbyLink>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Stack
            direction={["column", "row"]}
            spacing={6}
            justifyContent="flex-end"
            display={["none", null, "flex"]}
          >
            <Navigation color={color} />
            <LanguageSelector color={color} mobile={false} />
          </Stack>
          {/* <Button variant="link" color={color} onClick={toggleColorMode}>
            {colorMode === "light" ? <SunIcon /> : <MoonIcon />}
          </Button> */}
          <Button
            as="a"
            size="md"
            target="_blank"
            rel="noreferrer"
            href="https://fareharbor.com/embeds/book/xwandernordic/?full-items=yes&flow=646900"
          >
            {translate("navigation.bookOnline")}
          </Button>
          <MobileMenu color={color} />
        </Stack>
      </Flex>
    </Box>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
