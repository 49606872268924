import React, { useContext } from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
import { LocalizationContext } from "../context/LocalizationContext"
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form"

const ContactBlock = () => {
  const { language, translate } = useContext(LocalizationContext)

  let portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID
  let formId = process.env.GATSBY_HUBSPOT_FORM_ID

  // Language switch
  if (language === "fi") {
    portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID_FI
    formId = process.env.GATSBY_HUBSPOT_FORM_ID_FI
  } else if (language === "zh") {
    portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID_ZH
    formId = process.env.GATSBY_HUBSPOT_FORM_ID_ZH
  } else if (language === "es") {
    portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID_ES
    formId = process.env.GATSBY_HUBSPOT_FORM_ID_ES
  } else if (language === "de") {
    portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID_DE
    formId = process.env.GATSBY_HUBSPOT_FORM_ID_DE
  }

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: portalId,
    formId: formId,
    target: "#contactForm",
  })

  return (
    <Box id="contact">
      <Heading mb={4} size="lg">
        {translate("contactForm.title")}
      </Heading>
      <Text mb={8}>{translate("contactForm.subtitle")}</Text>
      <Box id="contactForm" />
    </Box>
  )
}

export default ContactBlock
