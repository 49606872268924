import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Box, Flex, Heading, useColorMode } from "@chakra-ui/react"

const PartnerLogos = () => {
  const data = useStaticQuery(graphql`
    fragment image on ImageSharp {
      fluid(maxHeight: 96) {
        ...GatsbyImageSharpFluid
      }
    }

    query {
      artisan: file(relativePath: { eq: "artisan.png" }) {
        childImageSharp {
          ...image
        }
      }
      biosphere: file(relativePath: { eq: "biosphere.png" }) {
        childImageSharp {
          ...image
        }
      }
      compensate: file(relativePath: { eq: "compensate.png" }) {
        childImageSharp {
          ...image
        }
      }
      visitfinland: file(relativePath: { eq: "visitfinland.png" }) {
        childImageSharp {
          ...image
        }
      }
    }
  `)

  const { colorMode } = useColorMode()
  const logoStyles = {
    margin: 24,
    maxHeight: 144,
    width: 144,
    filter:
      colorMode === "dark"
        ? "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(138deg) brightness(99%) contrast(103%)"
        : "none",
  }

  return (
    <Box py={24} textAlign="center">
      <Heading mb={4} size="lg">
        Partnering With
      </Heading>

      <Flex justifyContent="center" flexWrap="wrap">
        <Image
          fluid={data.visitfinland.childImageSharp.fluid}
          style={{ ...logoStyles }}
          imgStyle={{ objectFit: "contain" }}
          alt="Visit Finland"
        />
        <Image
          fluid={data.biosphere.childImageSharp.fluid}
          style={{ ...logoStyles }}
          imgStyle={{ objectFit: "contain" }}
          alt="Biosphere"
        />
        <Image
          fluid={data.artisan.childImageSharp.fluid}
          style={{ ...logoStyles }}
          imgStyle={{ objectFit: "contain" }}
          alt="The Artisan Travel Company"
        />
        <Image
          fluid={data.compensate.childImageSharp.fluid}
          style={{ ...logoStyles }}
          imgStyle={{ objectFit: "contain" }}
          alt="Compensate"
        />
      </Flex>
    </Box>
  )
}

export default PartnerLogos
