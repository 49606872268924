import React, { useContext } from "react"
import { Box, Container, Stack, Link, useColorMode } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import Logo from "./logo"
import ContactBlock from "./contactBlock"
import { LocalizationContext } from "../context/LocalizationContext"
import PartnerLogos from "./partnerLogos"

const Footer = () => {
  const { language } = useContext(LocalizationContext)
  const { colorMode } = useColorMode()

  let privacyLink =
    language === "en"
      ? `/privacy-policy`
      : `/${language}/privacy-policy-${language}`

  // Chinese condition
  if (language === "zh") {
    privacyLink = `/privacy-policy-${language}`
  }

  let termsLink =
    language === "en" ? `/terms` : `/${language}/terms-${language}`

  // Chinese condition
  if (language === "zh") {
    termsLink = `/terms-${language}`
  }

  return (
    <Container as="footer" maxWidth="5xl" py={16}>
      <ContactBlock />
      <PartnerLogos />
      <Stack alignItems="center" textAlign="center" spacing={6} mt={8}>
        <Box>
          <strong>Adventure Center Lapland</strong>
          <br />
          <span>Ivalontie 12</span>
          <br />
          <span>99800 Ivalo</span>
          <br />
          <Link href="tel:+358102007633">+358 10 200 7633</Link>
          <br />
          <Link href="mailto:nordic@xwander.com">nordic@xwander.com</Link>
        </Box>
        <GatsbyLink to="/">
          <Logo color={colorMode === "dark" ? "white" : "text"} />
        </GatsbyLink>
        <Stack direction="row" justifyContent="center">
          <Link as={GatsbyLink} to={privacyLink}>
            Privacy
          </Link>
          <Box>·</Box>
          <Link as={GatsbyLink} to={termsLink}>
            Terms
          </Link>
        </Stack>
        <Box>
          Xwander Nordic © {new Date().getFullYear()}. All rights reserved.
        </Box>
      </Stack>
    </Container>
  )
}
export default Footer
