import React, { useContext } from "react"
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { LocalizationContext } from "../context/LocalizationContext"
import { Link } from "./link"
import { navigate } from "gatsby"
import fiFlag from "../../static/flags/fi.svg"
import enFlag from "../../static/flags/gb.svg"
import deFlag from "../../static/flags/de.svg"
import esFlag from "../../static/flags/es.svg"
import zhFlag from "../../static/flags/cn.svg"

const locales = {
  fi: {
    name: "Suomi",
    url: "/fi",
    flag: fiFlag,
  },
  en: {
    name: "English",
    url: "/",
    flag: enFlag,
  },
  es: {
    name: "Español",
    url: "/es",
    flag: esFlag,
  },
  de: {
    name: "Deutsch",
    url: "/de",
    flag: deFlag,
  },
  zh: {
    name: "中文 (中国)",
    url: "https://www.xwander.cn",
    flag: zhFlag,
  },
}

const LanguageLinks = ({ mobile, setLanguage, language }) => {
  const isBrowser = () => typeof window !== "undefined"

  const handleChange = (lang) => {
    setLocale(lang)
    navigate("/" + lang)
  }

  const setLocale = (locale) => {
    setLanguage(locale)
    localStorage.setItem("userPreferredLanguage", locale)
  }

  return (
    <>
      <LanguageLink
        locale="en"
        mobile={mobile}
        onClick={(e) => {
          language === "zh" && isBrowser
            ? (window.location = "https://www.xwander.fi")
            : handleChange("")
        }}
      />
      <LanguageLink
        locale="fi"
        mobile={mobile}
        onClick={(e) => {
          language === "zh" && isBrowser
            ? (window.location = "https://www.xwander.fi/fi")
            : handleChange("fi")
        }}
      />
      <LanguageLink
        locale="es"
        mobile={mobile}
        onClick={(e) => {
          language === "zh" && isBrowser
            ? (window.location = "https://www.xwander.fi/es")
            : handleChange("es")
        }}
      />
      <LanguageLink
        locale="de"
        mobile={mobile}
        onClick={(e) => {
          language === "zh" && isBrowser
            ? (window.location = "https://www.xwander.fi/de")
            : handleChange("de")
        }}
      />
      <LanguageLink
        locale="zh"
        mobile={mobile}
        onClick={(e) => {
          e.preventDefault()
          if (isBrowser) window.location = "https://www.xwander.cn"
        }}
      />
    </>
  )
}

const LanguageLink = ({ locale, mobile, ...props }) => {
  const linkProps = {
    as: Link,
    to: locales[locale].url,
    ...props,
  }
  const linkChildren = (
    <>
      <Box
        as="img"
        src={locales[locale].flag}
        width={mobile ? 8 : 5}
        height={mobile ? 8 : 5}
        mr={2}
        alt={locales[locale].name}
      />
      <Box as="span" display={mobile ? "none" : "inline"}>
        {locales[locale].name}
      </Box>
    </>
  )
  return mobile ? (
    <Box {...linkProps} children={linkChildren} />
  ) : (
    <MenuItem {...linkProps} children={linkChildren} />
  )
}

const LanguageSelector = ({ color, mobile }) => {
  const { language, setLanguage } = useContext(LocalizationContext)

  return mobile ? (
    <LanguageLinks
      mobile={mobile}
      setLanguage={setLanguage}
      language={language}
    />
  ) : (
    <Menu direction="row" arrowPadding={1}>
      <MenuButton
        as={Button}
        variant="link"
        color={color}
        fontWeight="bold"
        rightIcon={<ChevronDownIcon />}
        iconSpacing={0}
        _active={{ color: { color } }}
        sx={{
          "span:first-of-type": {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <Box
          as="img"
          src={locales[language || "en"].flag}
          display="inline-block"
          width={5}
          height={5}
          aria-hidden="true"
          mr={1}
        />
        {language.toUpperCase() || "EN"}
      </MenuButton>
      <MenuList>
        <LanguageLinks
          mobile={mobile}
          setLanguage={setLanguage}
          language={language}
        />
      </MenuList>
    </Menu>
  )
}

export default LanguageSelector
